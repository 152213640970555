<template>
    <div class="pr-18px">
        <div class="flex flex-col">
          <div class="ws-action-box ws-select-dropdown flex gap-5 items-center justify-between mt-9">
            <div class="flex">
              <div class="flex filterWrapper">
                <div class="relative i-border-1 border-secondary-two rounded ws-search-width">
                  <input
                      type="text"
                      class="focus:outline-none py-2 w-full font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two"
                      :placeholder="searchField.placeholder"
                      v-model="searchField.text"
                      @click="searchField.placeholder = ''"
                      v-click-outside="outsideSearchInput"
                  />

                  <div class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-1">
                    <img
                        class="w-3 h-3"
                        src="/images/icons/search_icon.svg"
                    />
                  </div>
                </div>
              </div>
              <div class="i-ml-35">
                <select class="font-poppins fs-14 bg-transparent pl-2 pr-11 i-border-1 border-secondary-two rounded px-1 ws-select-dropdown ws-select-dropdown-width text-secondary-one" v-model="filter.project_creator">
                  <option value="all">Owned by anyone</option>
                  <option value="own">Owned by Me</option>
                </select>
              </div>
            </div>

            <div class="flex relative">
              <img src="/images/icons/message_icon.svg" alt="icon" class="cursor-pointer">
              <img src="/images/icons/notification_icon.svg" alt="icon" class="cursor-pointer i-ml-36" @click="notification = !notification">
              <div class="absolute dotted-notification bg-primary-five" v-if="unreadNotification"></div>
              <popup v-if="notification" />
            </div>
          </div>
        </div>

        <div class="mt-10">
        <!-- <h2 class="fs-18 font-poppins capitalize text-primary-one fw-600">{{ getFolderName(selected_folder) }} {{ getProjectItemType(type_selected) }}</h2> -->
        <div class="ws-projects-box mt-8 overflow-y-auto no-scrollbar h-725px">
          <div class="grid grid-cols-3 gap-35px">
            <div
                class="ws-project-box-item relative rounded bg-primary-three shadow-three i-pl-30 i-pr-30  i-pt-25 i-pb-25"
                v-for="(project, index) in getDisplayProjects"
                :key="index"
            >
              <div class="flex justify-between items-center i-border-b-1 border-secondary-two w-full pb-1">
                <router-link v-if="canRead(project.cans)" :to="{ name:'projectDashboard', params: { workspaceId: activeWorkspaceObj.id, projectId: project.id} }">
                  <h2 class="fs-14 font-poppins text-primary-one fw-600" @click="setActiveProject(project.id)">{{ project.name }}</h2>
                </router-link>
                <h2 v-else class="fs-14 font-poppins text-primary-one fw-600" @click="setActiveProject(project.id)">{{ project.name }}</h2>

                <div class="ws-project-opt-icon flex justify-center relative cursor-pointer" style="margin-top: -8px" @mouseover="mouseOver($event, index)" v-click-outside="outsideOption" @mouseleave="hoveredIndex = ''">
                  <img src="/images/icons/workspace/project_opt.svg" alt="icon" class="mt-1 opacity-40" :class="{'opacity-100': parseInt(hoveredIndex) === parseInt(index)}">
                  <div class="absolute ws-project-menus px-4 flex flex-col bg-primary-three shadow-two" v-if="parseInt(hoveredIndex) === parseInt(index)">
                    <router-link v-if="canRead(project.cans)" :to="{ name:'projectDashboard', params: { workspaceId: activeWorkspaceObj.id, projectId: project.id} }"><h6 class="font-poppins fs-14 text-secondary-one opacity-60 pt-2 pb-1 cursor-pointer card-option-menu">Open</h6></router-link>
                    <a
                      v-if="canEdit(project.cans)"
                      class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer card-option-menu"
                      @click="toggleEditModalMode(true, project)"
                    >Edit</a>
                    <a
                        v-if="canCreate(project.cans)"
                        class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer card-option-menu"
                        @click="duplicateProject(project)"
                    >Duplicate</a>
                    <router-link
                      v-if="canRead(project.cans)"
                      class="font-poppins fs-14 text-secondary-one opacity-60 py-3 cursor-pointer card-option-menu"
                      :to="{ name: 'workspace_settings', params: { id: activeWorkspaceObj.id }, query: { type: getProjectType(project.type), id: project.id } }">
                      <span>Settings</span>
                    </router-link>
                    <a
                      v-if="canDelete(project.cans)"
                      class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer card-option-menu"
                      @click="deleteProject(project.id)"
                    >Delete</a>
                  </div>
                </div>
              </div>
              <h6 class="fs-12 font-poppins text-secondary-two mt-2">{{ project.category }}</h6>
              <div class="project-content flex justify-between items-center">
                <div class="w-1/2">
                  <h2 class="fs-14 font-poppins text-secondary-one mt-2 truncate" :title="getUserName(project.created_by)">Owner: <span class="text-secondary-one">{{ project.created_by ? getUserName(project.created_by) : 'N/A' }}</span></h2>
                  <h2 class="fs-14 font-poppins text-secondary-one mt-2">Status: <span class="text-secondary-one">{{ project.status === 1 ? 'Published' : 'Draft' }}</span></h2>
                  <h2 class="fs-14 font-poppins text-secondary-one mt-2">Last edited: <span>{{ project.updated_at }}</span></h2>
                  <h2 class="fs-14 font-poppins text-secondary-one mt-2 truncate" :title="getUserName(project.created_by)">Creator: <span>{{ project.created_by ? getUserName(project.created_by) : 'N/A' }}</span></h2>
                </div>
                <div class="flex-shrink-0 i-mt-10">
                  <img :src="(project.file) ? project?.file?.file_path : '/images/project_thumbnail.jpg'" alt="demo" class="rounded project_image">
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>



        <p-e-modal 
          :dEditModal="dEditModal" 
          :selectedProject="selectedProject" 
          @toggleEditModalMode="toggleEditModalMode" 
        />

        <div class="absolute bg-primary-three shadow-two fs-14 text-primary-one font-poppins py-3 px-3 rounded"
            style="width: 80px; height: 80px"
            v-if="showContextMenu"
            :style="{'left': contextPos.left + 'px', 'top': contextPos.top + 'px'}">
            <ul>
              <li class="fw-600 cursor-pointer" @click="editWorkspace()">Edit</li>
              <li class="fw-600 cursor-pointer mt-3" @click="deleteWorkspace()">Delete</li>
            </ul>
        </div>

        <edit-workspace
          :dModal="eModal"
          :ref="'workspace_edit'"
          @toggleEditModal="toggleEditModal"
        />
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission';
  const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import Modal from '@/elements/atom/Modal'
import carousel from 'vue-owl-carousel2';
import { mapGetters } from 'vuex';
import WorkspaceMenu from "@/components/InstMenu/WorkspaceMenu";
import Popup from "@/components/notification/Popup";
import EditWorkspace from "@/components/workspace/WorkspaceEditModal";
import Navigation from "@/components/workspace/Navigation";

export default {
  name: "MyProjects",
  components: {EditWorkspace, Navigation, Popup, WorkspaceMenu, carousel },
  created() {
    const self = this;
    document.title = "Workspace Dashboard";
    if(self.workspaces.length <= 0) {
      self.$store.dispatch("workspace/fetchWorkspaces", 1).then(response => {
        if(response && response.data) {
          self.selectedWs = self.workspaces[0].id;
          self.$store.dispatch("workspace/toggleWorkspace", 0);
          self.$store.dispatch("workspace/getDirectories", self.workspaces[0].id);
          self.$store.dispatch("workspace/getWorkspaceData", {
            type: 1,
            workspace_id: self.workspaces[0].id
          });
        }
      });
    }
  },
  data() {
    return {
      eModal: false,
      contextPos: {
        top: 0,
        left: 0
      },
      showContextMenu: false,
      contextWorkspace: null,
      notification: false,
      dCreateModal: false,
      dEditModal: false,
      selectedProject: null,
      folder_name: '',
      visible_folder_name: false,
      type_selected: 1,
      hoveredIndex: '',
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      filter: {
        project_creator: 'all'
      },
      selectedWs: false
    }
  },
  computed: {
    ...mapGetters({
      workspaces: 'workspace/workspaces',
      activeWorkspaceObj: "workspace/activeWorkspaceObj",
      directories: "workspace/directories",
      workspaceInfos: "workspace/workspaceInfos",
      projectSelection: 'workspace/projectSelection',
      notifications: 'notification/notifications',
      selected_folder: 'workspace/selected_folder',
    }),
    getDisplayProjects() {
      const self = this;
      let projects = [];
      if(this.selected_folder === 'all') {
        projects = this.getAllInfo();
      } else if(this.selected_folder === 'repository') {
        projects = this.getRepositoryInfo();
      } else if(this.selected_folder === 'drafts') {
        projects = this.getPublicationDependentInfo(0);
      } else if(this.selected_folder === 'published') {
        projects = this.getPublicationDependentInfo(1);
      } else if(this.selected_folder === 'deleted') {
        projects = this.getDeletedInfo();
      } else {
        projects = this.getCustomDirectoryInfo(this.selected_folder);
      }

      if(this.filter.project_creator === 'own') {
        let user = JSON.parse(localStorage.getItem('_user'));
        projects = projects.filter(item => item.owner_id === user.id);
      }

      if(self.searchField.text) {
        return projects.filter((item) => {
          return self.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.name.toLowerCase().includes(v));
        });
      }
      return projects;
    },
    unreadNotification() {
      let unread = this.notifications.filter(item => !item.is_read);
      return unread && unread.length > 0;
    }
  },
  methods: {
    showWorkspaceCreateModal() {
      this.$store.dispatch("workspace/toggleWorkspaceModal", true);
    },
    toggleModalMode(value) {
      this.dCreateModal = value;
    },
    toggleEditModalMode(value, project = null) {
      this.dEditModal = value;
      this.selectedProject = project;
    },
    duplicateProject(project) {
      this.$Progress.start();
      this.$store.dispatch("project/duplicateProject", {
        id: project.id,
        w_id: this.activeWorkspaceObj.id
      }).then(response => {
        this.$Progress.finish();
        this.$store.dispatch("workspace/getWorkspaceData", {
          type: 1,
          workspace_id: this.activeWorkspaceObj.id
        });
      });
    },
    visibleNewFolder() {
      this.visible_folder_name = true;
    },
    saveFolder() {
      const self = this;
      if(self.folder_name) {
        self.$store.dispatch("workspace/createDirectory", {
          workspace_id: self.activeWorkspaceObj.id,
          name: self.folder_name
        }).then(response => {
          if(response) {
            self.visible_folder_name = false;
            self.$store.dispatch("workspace/getDirectories", self.activeWorkspaceObj.id);
          } else {
            alert('Something went wrong!!!');
          }
        });
      }
    },
    getAllInfo() {
      const self = this;
      return self.workspaceInfos;
    },
    getRepositoryInfo() {
      const self = this;
      return self.workspaceInfos.filter(item => !item.deleted_at || false || false);
    },
    getPublicationDependentInfo(status) {
      const self = this;
      return self.workspaceInfos.filter(item => (!item.deleted_at || false || false) && parseInt(item.status) === parseInt(status));
    },
    getDeletedInfo() {
      const self = this;
      return self.workspaceInfos.filter(item => item.deleted_at);
    },
    getCustomDirectoryInfo(directoryId) {
      const self = this;
      return self.workspaceInfos.filter(item => (!item.deleted_at || false || false) && parseInt(item.directory_id) === parseInt(directoryId));
    },
    toggleWorkspaceInfo(type) {
      const self = this;
      self.type_selected = type;
      self.$store.dispatch("workspace/getWorkspaceData", {
        type: type,
        workspace_id: self.activeWorkspaceObj.id
      });
    },
    toggleFolderSelection(folder) {
      this.selected_folder = folder;
    },
    getProjectItemType(type) {
      if(type === 1) {
        return 'Projects';
      } else if(type === 2) {
        return 'Blueprints';
      } else if(type === 3) {
        return 'Collections';
      }
    },
    getFolderName(folder) {
      if(isNaN(folder)) {
        return folder;
      } else {
        let item = this.directories.find(item => parseInt(item.id) === parseInt(folder));
        if(item) {
          return item.name;
        }
        return '';
      }
    },
    mouseOver(event, index) {
      this.hoveredIndex = index;
    },
    deleteProject(id) {
      const self = this;
      if(confirm("Do you want to delete this?")) {
        self.$store.dispatch("workspace/deleteProject", id).then(response => {
          if(response) {
            self.$store.dispatch("workspace/getWorkspaceData", {
              type: self.type_selected,
              workspace_id: self.activeWorkspaceObj.id
            });
          } else {
            alert('Something went wrong!!!');
          }
        })
      }
    },
    getUserName(user) {
      let title = '';
      if(user.first_name) {
        title = user.first_name;
        if(user.last_name) {
          title = title + ' ' + user.last_name;
        }
      } else {
        title = user.email;
      }
      return title.substring(0, 16);
    },
    outsideOption() {
      this.hoveredIndex = '';
    },
    outsideSearchInput() {
      this.searchField.placeholder = 'Search';
    },
    getProjectType(type) {
      type = parseInt(type);
      if(type === 1) {
        return 'project';
      } else if(type === 2) {
        return 'blueprint';
      } else {
        return 'collection';
      }
    },
    setActiveProject(id) {
      this.$store.dispatch("workspace/projectSelection", id);
      this.$store.dispatch("workspace/getActiveProject", id);
    },
    toggleWorkspace(id, index) {
      const self = this;
      self.selectedWs = id;
      self.$store.dispatch("workspace/toggleWorkspace", index);
      self.$store.dispatch("workspace/getDirectories", id);
      self.$store.dispatch("workspace/getWorkspaceData", {
        type: self.type_selected,
        workspace_id: id
      });
    },
    deleteFolder(id) {
      const self = this;
      if(confirm("Do you want to delete this?")) {
        self.$store.dispatch("workspace/deleteDirectory", id).then(response => {
          if(response) {
            self.$store.dispatch("workspace/getDirectories", self.activeWorkspaceObj.id);
            self.$store.dispatch("workspace/getWorkspaceData", {
              type: self.type_selected,
              workspace_id: self.activeWorkspaceObj.id
            });
            this.toggleFolderSelection('all')
          } else {
            alert('Something went wrong!!!');
          }
        })
      }
    },
    rightClickHandler(e, workspace) {
      e.preventDefault();
      this.showContextMenu = true;
      this.contextPos.left = e.pageX || e.clientX;
      this.contextPos.top = e.pageY || e.clientY;
      this.contextWorkspace = workspace;
    },
    hideContextMenu() {
      this.showContextMenu = false;
      this.contextWorkspace = null;
    },
    editWorkspace() {
      this.eModal = true;
      this.showContextMenu = false;
      this.$refs.workspace_edit.setEditableData(this.contextWorkspace);
    },
    deleteWorkspace() {
      this.$Progress.start();
      this.$store.dispatch("workspace/deleteWorkspace", {
        id: this.contextWorkspace.id
      }).then(response => {
        this.$Progress.finish();
      })
    },
    toggleEditModal() {
      this.eModal = false;
      this.contextWorkspace = null;
    },
  },
}
</script>

<style scoped>
  .ws-action-box{
    width: 100%;
  }
  .ws-select-dropdown-width{
    width: 214px;
  }
  .ws-search-width{
    width: 100%;
  }
  .project_image{
    width: 175px;
    display: block;
    height: 95px;
    object-fit: cover;
  }
  .ws-projects-box>div{
    width: 100%;
  }
  .filterWrapper{
    width: 462px;
  }
  .ws-project-box-item{
    width: 462px;
  }
  .w-project-title {
    padding: 5px 30px 0px;
  }
  .leftside-part {
    width: 335px;
  }
  .rightside-part {
    width: 1530px;
    margin-left: 2px;
  }
  .extra-folder-item:hover img {
    display: block;
  }

  /* @media all and (max-width: 1850px) and (min-width: 1300px){
    .ws-projects-box>div{
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    }
    .ws-projects-box .ws-project-box-item{
      max-width: 600px;
      width: 100%;
    }
    .project-content{
      display: grid;
      grid-template-columns: 1fr 150px;
      gap: 16px;
    }
    .project-content>div{
      width: 100%;
    }
  } */
</style>
